<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    width="250"
    disable-resize-watcher
    right
    overlay-opacity="0.8"
  >
    <!-- 博主介绍 -->
<!--    <div class="blogger-info">
      <v-avatar size="110" style="margin-bottom:0.5rem">
        <img :src="this.$store.state.blogInfo.webSite.authorAvatar" />
      </v-avatar>
    </div> -->
    <!-- 博客信息 -->
    <!-- <div class="blog-info-wrapper">
      <div class="blog-info-data">
        <router-link to="/archives">
          <div style="font-size: 0.875rem">文章</div>
          <div style="font-size: 1.125rem;">
            {{ this.$store.state.blogInfo.count.articleCount }}
          </div>
        </router-link>
      </div>
      <div class="blog-info-data">
        <router-link to="/categories">
          <div style="font-size: 0.875rem">分类</div>
          <div style="font-size: 1.125rem">
            {{ this.$store.state.blogInfo.count.categoryCount }}
          </div>
        </router-link>
      </div>
      <div class="blog-info-data">
        <router-link to="/tags">
          <div style="font-size: 0.875rem">标签</div>
          <div style="font-size: 1.125rem">
            {{ this.$store.state.blogInfo.count.tagCount }}
          </div>
        </router-link>
      </div>
    </div> -->
    <hr />
    <!-- 页面导航 -->
    <div class="menu-container">
      <div class="menus-item">
        <router-link to="/">
          <i class="iconfont iconzhuye" /> 首页
        </router-link>
      </div>
   <!--   <div class="menus-item">
        <router-link to="/archives">
          <i class="iconfont iconguidang" /> 归档
        </router-link>
      </div> -->
      <div class="menus-item"  @click="aiLogin">
        <router-link to="/">
          <i class="iconfont iconxiangce1" /> 小淳Ai
        </router-link>
      </div>
<!--    <div class="menus-item">
	    <router-link to="/room">
	      <i class="iconfont iconxiangce1" /> 放映厅
	    </router-link>
	  </div> -->
	<!--  <div class="menus-item">
	     <router-link to="/cs">
	       <i class="iconfont iconxiangce1" />测试
	     </router-link>
	   </div> -->
	  
      <!-- <div class="menus-item">
        <router-link to="/categories">
          <i class="iconfont iconfenlei" /> 分类
        </router-link>
      </div>
      <div class="menus-item">
        <router-link to="/tags">
          <i class="iconfont iconbiaoqian" /> 标签
        </router-link>
      </div>
      <div class="menus-item">
        <router-link to="/links">
          <i class="iconfont iconlianjie" /> 友链
        </router-link>
      </div> -->
      <div class="menus-item">
        <router-link to="/about">
          <i class="iconfont iconzhifeiji" /> 关于
        </router-link>
      </div>
      <div class="menus-item">
        <router-link to="/message">
          <i class="iconfont iconpinglunzu" /> 留言
        </router-link>
      </div>
      <div class="menus-item" v-if="!this.$store.state.avatar">
        <a @click="openLogin"><i class="iconfont icondenglu" /> 登录 </a>
      </div>
      <div v-else>
        <div class="menus-item">
          <router-link to="/user">
            <i class="iconfont icongerenzhongxin" /> 个人中心
          </router-link>
        </div>
        <div class="menus-item">
          <a @click="logout"><i class="iconfont icontuichu" /> 退出</a>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<style scoped>
.blogger-info {
  padding: 26px 30px 0;
  text-align: center;
}
.blog-info-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 10px 0;
}
.blog-info-data {
  flex: 1;
  line-height: 2;
  text-align: center;
}
hr {
  border: 2px dashed #d2ebfd;
  margin: 20px 0;
}
.menu-container {
  padding: 0 10px 40px;
  animation: 0.8s ease 0s 1 normal none running sidebarItem;
}
.menus-item a {
  padding: 6px 30px;
  display: block;
  line-height: 2;
}
.menus-item i {
  margin-right: 2rem;
}
@keyframes sidebarItem {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<script>
import {logout} from '../../api'
export default {
  data:function(){
    return {
      img: process.env.VUE_APP_IMG_API,
    }
  },
  computed: {
    drawer: {
      set(value) {
        this.$store.state.drawer = value;
      },
      get() {
        return this.$store.state.drawer;
      }
    },
    isLogin() {
      return this.$store.state.userId;
    }
  },
  methods: {
	  aiLogin() {
	  	if (this.$store.state.nickname === "" || this.$store.state.nickname === null) {
	  					    this.$store.state.loginFlag = true;
	  						this.$toast({
	  							type: "success",
	  							message: "请先登录"
	  						});
	  						this.istxing = 1;
	  						return;
	  					}
	  					this.$router.push({ path: "/albums" })
	  },
    openLogin() {
      this.$store.state.loginFlag = true;
    },
    logout() {
		//退回首页
		this.$router.push({ path: "/" })
      //如果在个人中心则跳回上一页
      // if (this.$route.path === "/user") {
      //   this.$router.go(-1);
      // }
      logout().then(res => {
          this.$store.commit("logout");
          this.$toast({ type: "success", message: "注销成功" });
      }).catch(err =>{
        console.log(err)
      });;
    }
  }
};
</script>
