<template>
	<v-dialog v-model="roomFlag" :fullscreen="isMobile" max-width="460">
		<v-card class="login-container" style="border-radius:4px">
			<v-icon class="float-right" @click="roomFlag = false">
				mdi-close
			</v-icon>
			<div class="login-wrapper">
				<!-- 用户名 -->
				<v-text-field v-model="this.$store.state.roomid" label="房间账号" :disabled="true" placeholder="请输入您的账号" @keyup.enter="login" />
				<!-- 密码 -->
				<v-text-field v-model="room.roompassword" class="mt-7" label="房间密码" :disabled="false" placeholder="请输入您的密码"
				 @keyup.enter="login" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'"
				 @click:append="show = !show" />
				<!-- 按钮 -->
				<v-btn class="mt-7" block color="blue" :disabled="false" style="color:#fff" @click="login">
					确认进入
				</v-btn>
			</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	import {
		emailLogin,
		getIsRoom
	} from "../../api";
	export default {
		data: function() {
			return {
				room: {
					roomid: "",
					roompassword: ""
				},
				username: "",
				roompassword: "",
				show: false
			};
		},
		computed: {
			roomFlag: {
				set(value) {
					this.$store.state.roomid = "";
					this.$store.state.roomFlag = value;
				},
				get() {

					return this.$store.state.roomFlag;
				}
			},
			isMobile() {
				const clientWidth = document.documentElement.clientWidth;
				if (clientWidth > 960) {
					return false;
				}
				return true;
			},
			socialLoginList() {
				return this.$store.state.blogInfo.webSite.loginTypeList;
			},
			showLogin() {
				return function(type) {
					return this.socialLoginList.indexOf(type) != -1;
				};
			}
		},
		methods: {
			login() {
				if (this.$store.state.nickname === "" || this.$store.state.nickname === null) {
					this.$store.state.loginFlag = true;
					this.$toast({
						type: "success",
						message: "请先登录"
					});
					this.istxing = 1;
					return;
				}
				if (this.room.roompassword.trim().length === 0) {
					this.$toast({
						type: "error",
						message: "密码不能为空"
					});
					return false;
				}
				this.room.roomid = this.$store.state.roomid;
				getIsRoom(this.room).then(res => {
					if (res.data == null) {
						this.$toast({
							type: "error",
							message: "密码错误"
						});
					} else {
						this.$store.state.roomSrc = res.data.src;
						this.$store.state.roomFlag = false;
						this.$router.push({
							path: "/bioscopes"
						})
					}
				}).catch(err => {
					this.$toast({
						type: "error",
						message: err.message
					});
				});
				//发送登录请求
				// emailLogin({email:this.username,password:this.password}).then(res => {
				//   this.username = "";
				//   this.password = "";
				//   this.$store.commit("login", res.data);
				//   this.$store.commit("closeModel");
				//   this.$toast({ type: "success", message: "登录成功" });
				// }).catch(err =>{
				//   this.$toast({ type: "error", message: err.message });
				// });
			},
			qqLogin() {
				//保留当前路径
				this.$store.commit("saveLoginUrl", this.$route.path);
				if (
					navigator.userAgent.match(
						/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
					)
				) {
					// eslint-disable-next-line no-undef
					QC.Login.showPopup({
						appId: this.config.QQ_CLIENT_ID,
						redirectURI: this.config.QQ_REDIRECT_URL
					});
				} else {
					window.open(
						"https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=" +
						+this.config.QQ_CLIENT_ID +
						"&response_type=token&scope=all&redirect_uri=" +
						this.config.QQ_REDIRECT_URL,
						"_self"
					);
				}
			},
			giteeLogin() {
				//保留当前路径
				this.$store.commit("saveLoginUrl", this.$route.path);
				window.open(
					"https://gitee.com/oauth/authorize?client_id=" +
					this.config.GITEE_CLIENT_ID +
					"&response_type=code&redirect_uri=" +
					this.config.GITEE_REDIRECT_URL,
					"_self"
				);
			},
			weiboLogin() {
				//保留当前路径
				this.$store.commit("saveLoginUrl", this.$route.path);
				window.open(
					"https://api.weibo.com/oauth2/authorize?client_id=" +
					this.config.WEIBO_CLIENT_ID +
					"&response_type=code&redirect_uri=" +
					this.config.WEIBO_REDIRECT_URL,
					"_self"
				);
			}
		}
	};
</script>

<style scoped>
	.social-login-title {
		margin-top: 1.5rem;
		color: #b5b5b5;
		font-size: 0.75rem;
		text-align: center;
	}

	.social-login-title::before {
		content: "";
		display: inline-block;
		background-color: #d8d8d8;
		width: 60px;
		height: 1px;
		margin: 0 12px;
		vertical-align: middle;
	}

	.social-login-title::after {
		content: "";
		display: inline-block;
		background-color: #d8d8d8;
		width: 60px;
		height: 1px;
		margin: 0 12px;
		vertical-align: middle;
	}

	.social-login-wrapper {
		margin-top: 1rem;
		font-size: 2rem;
		text-align: center;
	}

	.social-login-wrapper a {
		text-decoration: none;
	}
</style>
